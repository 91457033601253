import { default as abuseReportsPfHEu5ZZSCMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93XqGVjx67Q6Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexPZ51EhxVQ4Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/index.vue?macro=true";
import { default as moderationH6bIhJeRGrMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/moderation.vue?macro=true";
import { default as pendingBanshzJG6k8ISRMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/pendingBans.vue?macro=true";
import { default as profilesBoPICc2jJaMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/profiles.vue?macro=true";
import { default as expensesnm68mbAEQ2Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/expenses.vue?macro=true";
import { default as index4bL3pU1JfEMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewtdo8abEhwlMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaiting0Y4u4gX1hZMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingVnYN3OspGMMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/translations/missing.vue?macro=true";
import { default as users2PxVULYgpPMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/users.vue?macro=true";
import { default as apidhRQvYHVePMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/api.vue?macro=true";
import { default as _91slug_93pGKZ3iYiAqMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/blog/[slug].vue?macro=true";
import { default as indexHCMaNP4ejIMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93QWUIrKULc3Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93XHvKlgrWV6Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminIsF2PP2vIgMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/census/admin.vue?macro=true";
import { default as indexHPMU8fEa0uMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/census/index.vue?macro=true";
import { default as contact74pqJLPYLsMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/contact.vue?macro=true";
import { default as designeBJpbyuTXGMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/design.vue?macro=true";
import { default as englishSQ7YzSdnqfMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/english.vue?macro=true";
import { default as faqHwIGEAKXpJMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/faq.vue?macro=true";
import { default as inclusiveFAHitVbqhHMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/inclusive.vue?macro=true";
import { default as indexUElpOZXrIFMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/index.vue?macro=true";
import { default as licenseIXiPi7WojeMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/license.vue?macro=true";
import { default as academictJOsaeMexzMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/links/academic.vue?macro=true";
import { default as indexXnr11BLSTTMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/links/index.vue?macro=true";
import { default as mediaglVLYJOZRqMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/links/media.vue?macro=true";
import { default as translinguisticsYRVhk7gzS4Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/links/translinguistics.vue?macro=true";
import { default as zineZeBWwoGIqaMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/links/zine.vue?macro=true";
import { default as namessKy4WQ3LsFMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/names.vue?macro=true";
import { default as index3SQ2fMQEwJMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/nouns/index.vue?macro=true";
import { default as templates8x0P7hVZprMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/nouns/templates.vue?macro=true";
import { default as peopletY3GDurmdQMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/people.vue?macro=true";
import { default as privacyX5222mc5GVMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/privacy.vue?macro=true";
import { default as _91username_93yoeKb3O6izMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93fBxXuT5a68Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/card-[username].vue?macro=true";
import { default as editorQYwq0NP1CDMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/editor.vue?macro=true";
import { default as anyzsY44urgTsMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/any.vue?macro=true";
import { default as askfjoEWMoLeNMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/ask.vue?macro=true";
import { default as avoiding3UR80q8SDaMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/avoiding.vue?macro=true";
import { default as index1fH0IbkYNJMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/index.vue?macro=true";
import { default as mirrorq43hGuVtywMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/mirror.vue?macro=true";
import { default as pronounn5W5JYGIMvMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesc3MjhFcZEBMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/sources.vue?macro=true";
import { default as teamw6A9QPeEJdMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/team.vue?macro=true";
import { default as terminologysqoryZs1iPMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/terminology.vue?macro=true";
import { default as termsn4fFuA1CN6Meta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/terms.vue?macro=true";
import { default as userRTlw12doIBMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/user.vue?macro=true";
import { default as workshopsLhGjDCTfxyMeta } from "/home/admin/www/zaimki.pl/release/20241214183602/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsPfHEu5ZZSCMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanshzJG6k8ISRMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apidhRQvYHVePMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93pGKZ3iYiAqMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexHCMaNP4ejIMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93QWUIrKULc3Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93XHvKlgrWV6Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminIsF2PP2vIgMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexHPMU8fEa0uMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact74pqJLPYLsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishSQ7YzSdnqfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqHwIGEAKXpJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveFAHitVbqhHMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academictJOsaeMexzMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexXnr11BLSTTMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaglVLYJOZRqMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsYRVhk7gzS4Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineZeBWwoGIqaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namessKy4WQ3LsFMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index3SQ2fMQEwJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates8x0P7hVZprMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopletY3GDurmdQMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyX5222mc5GVMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93yoeKb3O6izMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93fBxXuT5a68Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorQYwq0NP1CDMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyzsY44urgTsMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askfjoEWMoLeNMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding3UR80q8SDaMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index1fH0IbkYNJMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorq43hGuVtywMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounn5W5JYGIMvMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesc3MjhFcZEBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamw6A9QPeEJdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologysqoryZs1iPMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsn4fFuA1CN6Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userRTlw12doIBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsLhGjDCTfxyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20241214183602/pages/workshops.vue")
  },
  {
    name: "nouns-neutratywy",
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue")
  },
  {
    name: "nouns-dukatywy",
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue")
  },
  {
    name: "nouns-osobatywy",
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue")
  },
  {
    name: "nouns-iksatywy",
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue")
  }
]